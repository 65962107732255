// TODO: revisit naming of all of these (variants, colors, etc.)
// This is a "first level" abstraction for reuse

import { classList } from '~/utilities/css';

const sharedButtonStyles = 'uic-text-center uic-rounded-md uic-px-10 uic-py-8 uic-font-quicksand uic-font-bold';

const styles = {
	PrimaryButton: classList([
		'uic-bg-azure uic-text-white uic-rounded-md',
		sharedButtonStyles,
	]),

	SecondaryButton: classList([
		'uic-bg-wolf uic-text-raisin',
		sharedButtonStyles,
	]),

	NegativeButton: classList([
		'uic-bg-watermelon uic-text-white',
		sharedButtonStyles,
	]),

	OutlinedButton: classList([
		'uic-bg-white',
		'uic-flex uic-items-center',
		'uic-border-1 uic-border-mercury uic-rounded-md',
		'uic-text-xxs uic-font-semibold',
		'uic-px-10 uic-py-[7px]',
	]),

	TextButton: classList([
		'focus:uic-outline-dotted focus:uic-outline-1',
		'uic-text-azure uic-rounded-md',
		'uic-px-10 uic-py-[7px]',
	]),
};

export { styles as default, styles as ButtonStyles };
