import { classList } from '~/utilities/css';

const styles = {
	Tag: classList([
		'uic-uppercase uic-font-semibold uic-text-10 uic-text-black uic-text-center',
		'uic-bg-solitude uic-rounded-full',
		'uic-px-15 uic-py-[5px]',
	]),

	CautionTag: classList([
		'uic-uppercase uic-font-semibold uic-text-10 uic-text-black uic-text-center',
		'uic-bg-lemon uic-rounded-full',
		'uic-px-15 uic-py-[5px]',
	]),

	PositiveTag: classList([
		'uic-uppercase uic-font-semibold uic-text-10 uic-text-white uic-text-center',
		'uic-bg-prince uic-rounded-full',
		'uic-px-15 uic-py-[5px]',
	]),
};

export { styles as default, styles as TagStyles };
