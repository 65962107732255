import { ButtonStyles } from './button';
import { TableStyles } from './table';
import { TagStyles } from './tag';

const styles = {
	...ButtonStyles,
	...TableStyles,
	...TagStyles,
};

export { styles as default, styles as ComponentStyles };
