import { classList } from '~/utilities/css';

const styles = {
	table: {
		container: classList([
			'max-desktop:uic-truncate-table',
			'uic-table-auto uic-w-full uic-text-left uic-text-xs',
		]),
		data: 'uic-py-10 uic-px-15',
	},
};

export { styles as default, styles as TableStyles };
